import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Col,Row} from 'react-bootstrap'
import axios from 'axios'
import {Redirect,useHistory,Link} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'
import {PurchaseContext} from './PurchaseContext'

export default function PricingPaymentMethod(props) {

    const {activeTab,setActiveTab}=useContext(UserContext)

    const [isLoading,setIsLoading]=useState(false)
    const {purchaseItem,setPurchaseItem}=useContext(PurchaseContext)
    const history=useHistory()
    const [purObj,setPurObj]=useState({})


    useEffect(()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'eshopusrlicences/'+sessionStorage.getItem("userid")+'/'+props.lid,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
              }            
          })
        .then(res=>{
          if (res.data.length==1){
            //console.log(res.data[0])
            setPurchaseItem(res.data[0])
            setIsLoading(false)
            const vatPerCent=res.data[0].CustomerType==2 ? res.data[0].iVatPerCent.toFixed(2) : res.data[0].cVatPerCent.toFixed(2)
            const initialPriceWithDiscount=res.data[0].ElorusProductPrice.toFixed(2)-res.data[0].Discount.toFixed(2)
            const vatValue=(vatPerCent/100)*initialPriceWithDiscount
            const payValue=initialPriceWithDiscount+vatValue
            const xobj={
                initialPrice:res.data[0].ElorusProductPrice.toFixed(2),
                discount:res.data[0].Discount.toFixed(2),
                initialPriceWithDiscount,
                vatPerCent,
                vatValue:vatValue.toFixed(2),
                payValue:payValue.toFixed(2),
                PaymentMethod:res.data[0].PaymentMethod
            }
            setPurchaseItem({
                ...purchaseItem,
                initialPrice:xobj.initialPrice,
                discount:xobj.discount,
                initialPriceWithDiscount:xobj.initialPriceWithDiscount,
                payValue:xobj.payValue,
                vatPerCent:xobj.vatPerCent,
                vatValue:xobj.vatValue,
                PaymentMethod:xobj.PaymentMethod
            })
            setPurObj(xobj)
            //console.log(res.data[0])
            //createCheckoutSession(res.data[0].eshopuserslicencesid)
          }else{
            alert("An Error Has Occured")
            history.push('/licences')
          }
        })   
  
      },[activeTab])



      const save=(e)=>{
        e.preventDefault()
        setIsLoading(true)
       // setActiveTab('checkout')
          axios.put(process.env.REACT_APP_API_SERVER+'eshopusrlicences/'+props.lid,{PaymentMethod:purchaseItem.PaymentMethod,PayValue:purchaseItem.payValue},{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
                }            
            })
            .then(res1=>{
      
              if (res1.data.err==0){
                  setIsLoading(false)
                  //console.log(purchaseItem)
                  setActiveTab('checkout') 
              }else{
                  alert('An error has occured. Please try again...')
              }
          })
      }



  return (
    <Container fluid>
    <Alert variant='secondary' style={{backgroundColor:'#55505c',color:'#ffffff'}}>
    
        <Row>
        <Col md="auto">
        <h4>Pricing and Payment Method</h4>
        </Col>
        <Col></Col>

      </Row>

    </Alert>
    {isLoading &&
        <Alert variant='warning'>...Loading, please wait.</Alert>
    }
    {!isLoading &&

    <>
        <b>Initial Price: </b>{purObj.initialPrice}€<br/>
        <b>Discount: </b>{purObj.discount}€<br/>
        <b>Vat Tax (%): </b>{purObj.vatPerCent}%<br/>
        <b>Vat Tax Value : </b>{purObj.vatValue}€<br/><br/>
        <b>TOTAL : </b>{purObj.payValue}€
        <hr/>

   

    <Form onSubmit={save}>
    <Form.Group className="mb-3" controlId="formBasicPassword">   
          <Form.Label><b>Payment Method</b></Form.Label>    
          <Form.Select required value={purchaseItem.PaymentMethod} onChange={(e)=>{setPurchaseItem({...purchaseItem,PaymentMethod:e.target.value})}}>
          <option value=''>[SELECT PAYMENT METHOD]</option>
          <option value='1'>CREDIT CARD VIA STRIPE</option>
          <option value='2'>BANK TRANSFER</option>
          </Form.Select>
      </Form.Group>


      <Navbar bg="light" fixed="bottom" >
        <table width="100%">
          <tbody>
          <tr>
            <td align="right">
            <Button variant="secondary" onClick={()=>setActiveTab('profile')} style={{width:"200px"}}>Back</Button>  
            &nbsp;&nbsp; 
            <Button  disabled={!(purchaseItem.PaymentMethod==1 || purchaseItem.PaymentMethod==2)} variant="dark" type="submit" style={{width:"200px"}}>
              Save and Continue
            </Button> 
            </td>
            <td width="30"></td>
          </tr>
          </tbody>
        </table>
    </Navbar>
  </Form>
  </>
    }
    </Container>
  )
}


import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Row,Col} from 'react-bootstrap'
import axios from 'axios'
import {Redirect,useHistory,Link} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop, faShoppingBasket,faShoppingCart,faFileInvoice } from '@fortawesome/free-solid-svg-icons'

import DataTable from 'react-data-table-component';




const customStyles = {
    rows: {
        style: {
            minHeight: '25px',
            fontSize:'12px'
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor:'#55505c',
            color:'#fff'
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            flex: 1
        },
    },
};


export default function Licences() {
    
    const [data,setData]=useState([])
    const {purchaseItem,setPurchaseItem}=useContext(UserContext)
    const history=useHistory()
    const [isLoading,setIsLoading]=useState(false)
    const {activeTab,setActiveTab}=useContext(UserContext)




    const columns = [

        {
            name: '',
            selector: row => {
                if (row.Status==1 && row.Activated==1){
                    return(
                        <Button variant="link" style={{color:'#94c9a9',textDecoration:'none'}} onClick={()=>history.push('/licences/'+row.lid) }><FontAwesomeIcon icon={faEdit}/></Button>
                    )
                }else{

                }
            } ,
            sortable: true,
            maxWidth: "15px"
        },
        {
            name: 'Product',
            selector: row =>  row.ElorusProductTitle  ,
            sortable: true
        },
        {
            name: 'Price (€)',
            selector: row =>  row.ElorusProductPrice ? row.ElorusProductPrice.toFixed(2) : '' ,
            sortable: true,
           // maxWidth: "50px"
        },
        {
            name: 'Discount (€)',
            selector: row =>  row.Discount.toFixed(2) ,
            sortable: true,
           // maxWidth: "50px"
        },        
        
        {
            name: 'Total (€)',
            selector: row =>  row.ElorusProductPrice.toFixed(2)-row.Discount.toFixed(2) ,
            sortable: true,
            //maxWidth: "50px"
        },        
        {
            name: 'VAT Tax (%Total)',
            selector: row =>  row.CustomerType==1 ? row.cVatPerCent +'%': row.iVatPerCent +'%',
            sortable: true,
            //maxWidth: "50px"
        },        
        {
            name: 'Pay Value (€)',
            selector: row =>  {
                if (row.CustomerType==1){
                    var vatVal=1+row.cVatPerCent.toFixed(2)/100
                }else{
                    var vatVal=1+row.iVatPerCent.toFixed(2)/100 
                }
                const total=row.ElorusProductPrice.toFixed(2)-row.Discount.toFixed(2) 
                var payValue=total*vatVal
                return(payValue.toFixed(2))
            },
            sortable: true,
            //maxWidth: "50px"
        },    
        {
            name: 'Document',
            selector: row => {
                if (row.ElorusDocumentLink){
                    
                        if (row.ElorusDocumentLink.length>0){
                            return( 
                            <Button variant="link" style={{color:'gray',fontSize:'12px'}} href={row.ElorusDocumentLink} target="_blank">{row.DocumentNumber}</Button>
                            )
                        }
                   
                }
            } 
            //sortable: true,
            //maxWidth: "100px"
        },      
        
        {
            name: 'Date',
            selector: row => row.TransactionDate,
            sortable: true,
            maxWidth: "50px"
        },         

        {
            name: 'State',
            selector: row => {
                if (row.Status==1){
                    if (row.Activated==1){
                        return(
                            <font color='green'>Activated</font>
                        )
                    }else{
                        return(
                        <font color= 'red'>Pending Activation</font>
                        )
                    }
                }else{
                    return(
                        <Button variant="link" style={{color:'#94c9a9',textDecoration:'none'}} onClick={()=>showForm(row)}><FontAwesomeIcon icon={faShoppingCart}/> Purchase Product</Button> 
                    )
                }
            },
            //<font color={row.Status==1 ? 'green' : 'red'}>{row.Status==1 ? 'Activated' : 'Pending'}</font>,
            sortable: false,
            //maxWidth: "50px"
        }       
    ];


    useEffect(()=>{
        setActiveTab('profile')
        loadData()
    },[])



    const loadData=()=>{
        axios.get(process.env.REACT_APP_API_SERVER+'eshopusrlicences/'+sessionStorage.getItem("userid"),{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
                }            
            })
          .then(res=>{
            setData(res.data)
           // console.log(res.data)
            setIsLoading(false)
        })     
    }

    const showForm=(item)=>{
        setPurchaseItem(item)
        
        sessionStorage.setItem("purchaseItem",item)
       // console.log(sessionStorage.getItem("purchaseItem"))
        history.push('/purchase/'+item.lid)   
    }




    return (    

        <Container fluid>
            <Alert variant='secondary' style={{backgroundColor:'#55505c',color:'#ffffff'}}>
            
                <Row>
                <Col md="auto">
                <h4>Licences</h4>
                </Col>
                <Col></Col>

              </Row>


            </Alert>
            {isLoading &&
                <Alert variant='warning'>...Loading, please wait.</Alert>
            }
            {!isLoading && 

                <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                highlightOnHover
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
                />



        }


        </Container>        

    )
}

import React, {useState,useContext,createContext,useEffect}  from 'react'
import {BrowserRouter as Router, Switch,Route,HashRouter} from 'react-router-dom'


import Login from './components/Login'
import {UserContext} from './context/UserContext'
import ProtectedRoute from './ProtectedRoute'
import Dashboard from './components/protected/Dashboard'
import Licences from './components/protected/Licences'
import Documents from './components/protected/Documents'
import Purchase from './components/protected/Purchase'
import PurchaseSuccess from './components/protected/PurchaseSuccess'
import PurchaseCancel from './components/protected/PurchaseCancel'
import Profile from './components/protected/Profile'
import Licence from './components/protected/Licence'
import Precover from './components/protected/Precover'

export default function App() {

  const [isAuth,setIsAuth]=useState(false)
  const [token,setToken]=useState(null)
  const [purchaseItem,setPurchaseItem]=useState({})
  const [activeTab,setActiveTab]=useState('profile')
  const [purchaseTabs,setPurchaseTabs]=useState([true,true])
  const [selectedMenuItem,setSelectedMenuItem]=useState(1)

  const logout=()=>{
    setIsAuth(false)
    sessionStorage.setItem("isAuthEshop", 0)
    sessionStorage.setItem("eshoptoken", null) 

  }

  return (
    <div>
    <UserContext.Provider value={{selectedMenuItem,purchaseTabs,setPurchaseTabs,activeTab,setActiveTab,isAuth,setIsAuth,token,setToken,logout,purchaseItem,setPurchaseItem}}>
    <HashRouter basename={'/'}>

      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/precover/:uid" component={Precover} />
      <ProtectedRoute exact path="/licences" component={Licences} />
      <ProtectedRoute exact path="/profile" component={Profile} />
      <ProtectedRoute exact path="/dashboard" component={Dashboard} />
      <ProtectedRoute exact path="/documents" component={Documents} />
      <ProtectedRoute exact path="/purchase/:lid" component={Purchase} />
      <ProtectedRoute exact path="/licences/:lid" component={Licence} />
      <ProtectedRoute exact path="/purchasesuccess" component={PurchaseSuccess} />
      <ProtectedRoute exact path="/purchasecancel" component={PurchaseCancel} />
      
      
      {/* <ProtectedRoute exact path="/products" component={Products} />
      <ProtectedRoute exact path="/elorus" component={Elorus} />
      <ProtectedRoute exact path="/sendinblue" component={Sendinblue} />
      <ProtectedRoute exact path="/apis" component={Apis} />
      <ProtectedRoute exact path="/endpoints/:idAPI" component={ApiEndpoints} />
      <ProtectedRoute exact path="/licences" component={Licences} />
      <ProtectedRoute exact path="/licences/:id" component={UserLicenceDetails} />
      <ProtectedRoute exact path="/chargetypes" component={ChargeTypes} />
      <ProtectedRoute exact path="/emailtemplates" component={EmailTemplates} />
      <ProtectedRoute exact path="/users" component={Users} />
      <ProtectedRoute exact path="/countries" component={Countries} /> */}
    </HashRouter>
    </UserContext.Provider>
    </div>
  )
}

import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Col,Row} from 'react-bootstrap'
import axios from 'axios'
import {useLocation,useHistory} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'


export default function PurchaseSuccess() {
    const search = useLocation().search
    const [invLink,setInvLink]=useState('')
    const [isLoading,setIsLoading]=useState(false)
    const history=useHistory()

    useEffect(()=>{
        const session_id = new URLSearchParams(search).get('session_id');

        setIsLoading(true)
        const obj={
            ccsessionid:session_id,
            uid:sessionStorage.getItem('userid')
        }
        axios.post(process.env.REACT_APP_API_SERVER+'finishorder',obj,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
                }            
            })
        .then(res=>{
            //console.log(res.data)
            setInvLink(res.data.permalink)
            setIsLoading(false)
            history.push('/licences/'+res.data.lid)
        }).catch(err=>{
            //console.log(err)
            setIsLoading(false)
            alert('An Error Has Occured')
        }) 

    },[])



    return (
        <>
        <Alert variant="success"><h4>Your Purchase Was Successful !!!!</h4></Alert>
        {isLoading &&
            <Alert variant='warning'>...Loading, please wait.</Alert>
          }

        {!isLoading && 
            
            <a href={invLink} target="_blank">Invoice {invLink}</a>
            
            }

    </>
    )
}

import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Col,Row} from 'react-bootstrap'
import Logo from './neurored.png'
import api_keys from './icons/api_keys.png'
import Logout from './icons/Logout.png'
import {UserContext} from '../../context/UserContext'
import Invoices from './icons/Invoices.png'
import users from './icons/users.png'

export default function SideBar() {
  const {logout}=useContext(UserContext)
  const {selectedMenuItem}=useContext(UserContext)

  return (
    <>
    <img src={Logo} width="200vw"/>
    <Navbar>
    
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav defaultActiveKey="#/licences" className="flex-column">
    <Nav.Link style={{color:'#f0f0f0',fontWeight:'bold',fontSize:'14px'}} eventKey="licences" href="#/licences">
    <img src={api_keys} width="35"/>&nbsp;&nbsp;Licences
    </Nav.Link>

    <Nav.Link style={{color:'#f0f0f0',fontWeight:'bold',fontSize:'14px'}} eventKey="licences" href="#/profile">
    <img src={users} width="35"/>&nbsp;&nbsp;User Details
    </Nav.Link>
    <Nav.Link style={{color:'#f0f0f0',fontWeight:'bold',fontSize:'14px'}} eventKey="logout" href="#" onClick={()=>logout()}>
    <img src={Logout} width="35"/>&nbsp;&nbsp;LOGOUT
      </Nav.Link>
    </Nav>
    </Navbar.Collapse>
    </Navbar>
    </>
  )
}

import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Col,Row} from 'react-bootstrap'
import axios from 'axios'
import {Redirect,useHistory,Link} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'

export default function Dashboard() {

  const [data,setData]=useState([])
  const [show,setShow]=useState(false)
  const [modalTitle,setModalTitle]=useState('')
  const [record,setRecord]=useState({})
  const [isLoading,setIsLoading]=useState(false)
  const [profileUpdated,setProfileUpdated]=useState(sessionStorage.getItem("profileUpdated")==1 ? true : false)
  const [user,setUser]=useState({})
  const [countries,setCountries]=useState([])
  const [vatIsValid,setVatIsValid]=useState({})
  const {purchaseItem,setPurchaseItem}=useContext(UserContext)
  const history=useHistory()

  useEffect(()=>{

    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'userprofile/'+sessionStorage.getItem("userid"),{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
          }            
      })
    .then(res1=>{
      setUser(res1.data[0])
      //console.log(res1)
      if (res1.data[0].VATNumber.length>0){
        setVatIsValid({valid:true})
      }
      loadData()
    })    


  },[])

  const loadData=()=>{
    axios.get(process.env.REACT_APP_API_SERVER+'userlicences/'+sessionStorage.getItem("userid"),{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
          }            
      })
    .then(res=>{
      setData(res.data)
      loadCounties()
      setIsLoading(false)
    }) 
  }

  const loadCounties=()=>{
    axios.get(process.env.REACT_APP_API_SERVER+'countries',{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
            }            
        })
      .then(res=>{
        setCountries(res.data)
        //console.log(res.data)
      })        
  }

  const showForm=(item)=>{
    setPurchaseItem(item)
    history.push('/purchase')    
  }

  const handleClose=()=>{
    setShow(false)
  }

  const save=(e)=>{
    e.preventDefault()

    
    axios.put(process.env.REACT_APP_API_SERVER+'userprofile/'+sessionStorage.getItem("userid"),user,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
          }            
      })
      .then(res1=>{

      if (res1.data.err==0){
        sessionStorage.setItem('profileUpdated',1)
        sessionStorage.setItem('FirstName',user.FirstName)
        sessionStorage.setItem('LastName',user.LastName)
        setProfileUpdated(1)
        setVatIsValid({valid:true})
        loadData()
      }else{
          alert('An error has occured. Please try again...')
      }
    })
  }

  const countriesList=countries.map(item=>{
    return(
        <option key={item.CountryID} value={item.CountryID}>{item.Country}</option>
    )
  })


  const dataList=data.map(item=>{
    const Total=item.ElorusProductPrice.toFixed(2)-item.Discount.toFixed(2)
    var Vat=0
    if (item.CustomerType==1){
       Vat=item.cVatPerCent
    }else{
      Vat=item.iVatPerCent
    }
    const PayValue=Total+Total*(Vat/100)
    return(
        <tr key={item.eshopuserslicencesid}>
        <td>{item.ElorusProductTitle}</td>
        <td>{item.ElorusProductPrice ? item.ElorusProductPrice.toFixed(2) : ''}</td>
        <td>{item.Discount.toFixed(2)}</td>
        <td>{Total.toFixed(2)}</td>
        <td>{Vat}%</td>
        <td>{PayValue.toFixed(2)}</td>
        <td>{item.Status==0 ? <Button variant="link" onClick={()=>showForm(item)}>Purchase Product</Button> : ''}</td>
        <td><font color={item.Status==1 ? 'green' : 'red'}>{item.Status==1 ? 'Activated' : 'Pending'}</font></td>
      

      
      </tr>            
    )
})

const changeCountry=(CountryID)=>{
  setVatIsValid({})
  const country=countries.filter(country=>country.CountryID==CountryID)[0]
  setUser({...user, VATNumber:'',CountryID,EU:country.EU,CountryName:country.CountryName})
}

  const checkVatNum=(VatNumber)=>{
    setUser({...user,VATNumber:VatNumber})
    if (user.EU==1){
      const vatObj={
        CountryCode:user.VIESCode,
        VatNumber
      }

      axios.post(process.env.REACT_APP_API_SERVER+'checkvat',vatObj,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
            }            
        })
      .then(res=>{
        setVatIsValid(res.data)

        
      }) 
    }else{
      if (VatNumber.length>5){
        setVatIsValid({...vatIsValid,valid:true,name:user.Company})
      }
    }
  }


  const changeCustomerType=(customerType)=>{
    if (customerType==1){
      setVatIsValid({})
    }else{
      setVatIsValid({valid:true})
    }
    setUser({...user,CustomerType:customerType})
  }

  return (
    <>
    <Alert variant="secondary"><h4>Dashboard</h4></Alert>

    <h4>Welcome<Button variant="link" onClick={()=>setProfileUpdated(0)}><h4><b>{sessionStorage.getItem("userFullName")}</b></h4></Button></h4>
    <hr/>
    {isLoading &&
      <Alert variant='warning'>...Loading, please wait.</Alert>
    }

    {profileUpdated && !isLoading && 
    <>
    <img src="./APIKeys.svg"/>
    <Table striped bordered hover>
                <thead>
                    <tr>
                    <th width="30%">PRODUCT</th>
                    <th>PRICE (€)</th>
                    <th>DISCOUNT (€)</th>
                    <th>TOTAL (€)</th>
                    <th>VAT TAX (%TOTAL)</th>
                    <th>PAY VALUE (€)</th>
                    <th></th>
                    <th width="15%">STATE</th>
                    </tr>
                </thead>
                
                <tbody>
                {dataList}
                </tbody>
        </Table>
        </>
  }
  
  {!profileUpdated && !isLoading &&  
    <Container>
    <Form onSubmit={save}>
      <Form.Group controlId="formBasicEmail">
          <Form.Label><b>Company</b></Form.Label>
          <Form.Control  type="text" required value={user.Company} onChange={(e)=>{setUser({...user,Company:e.target.value})}}/>
      </Form.Group>
      <br/>

      <Form.Group controlId="formBasicEmail">
          <Form.Label><b>First Name</b></Form.Label>
          <Form.Control type="text" required value={user.FirstName} onChange={(e)=>{setUser({...user,FirstName:e.target.value})}}/>
      </Form.Group>
      <br/>

      <Form.Group controlId="formBasicEmail">
          <Form.Label><b>Last Name</b></Form.Label>
          <Form.Control type="text" required value={user.LastName} onChange={(e)=>{setUser({...user,LastName:e.target.value})}}/>
      </Form.Group>
      <br/>

      <Form.Group controlId="formBasicEmail">
          <Form.Label><b>Email</b></Form.Label>
          <Form.Control disabled={true} type="text" required value={user.Email} />
      </Form.Group>
      <br/>

      <Form.Group controlId="formBasicEmail">
          <Form.Label><b>Telephone</b></Form.Label>
          <Form.Control type="text" required value={user.Telephone} onChange={(e)=>{setUser({...user,Telephone:e.target.value})}}/>
      </Form.Group>
      <br/>

      <Form.Group controlId="formBasicEmail">
          <Form.Label><b>Address</b></Form.Label>
          <Form.Control type="text" required value={user.Address} onChange={(e)=>{setUser({...user,Address:e.target.value})}}/>
      </Form.Group>
      <br/>

      <Form.Group className="mb-3" controlId="formBasicPassword">   
          <Form.Label><b>Country</b></Form.Label>    
          <Form.Select required value={user.CountryID} onChange={(e)=>changeCountry(e.target.value)}>
          <option value=''>[SELECT COUNTRY]</option>
          {countriesList}
          </Form.Select>
      </Form.Group>
      <br/>

      <Form.Group controlId="formBasicEmail">
          <Form.Label><b>State/Province</b></Form.Label>
          <Form.Control type="text" required value={user.State} onChange={(e)=>{setUser({...user,State:e.target.value})}}/>
      </Form.Group>
      <br/>

      <Form.Group controlId="formBasicEmail">
          <Form.Label><b>City</b></Form.Label>
          <Form.Control type="text" required value={user.City} onChange={(e)=>{setUser({...user,City:e.target.value})}}/>
      </Form.Group>
      <br/>

      <Form.Group controlId="formBasicEmail">
          <Form.Label><b>Postal Code</b></Form.Label>
          <Form.Control type="text" required value={user.PostalCode} onChange={(e)=>{setUser({...user,PostalCode:e.target.value})}}/>
      </Form.Group>
      <br/>



      <Form.Group className="mb-3" controlId="formBasicPassword">   
          <Form.Label><b>Purchase as</b></Form.Label>    
          <Form.Select required value={user.CustomerType} onChange={(e)=>changeCustomerType(e.target.value)}>
          <option value=''>[PURCHASE AS...]</option>
          <option value="1">Company</option>
          <option value="2">Individual</option>
          </Form.Select>
      </Form.Group>
      <br/>      

      {user.CustomerType==1 && user.CountryID &&
        <>
        <Form.Group controlId="formBasicEmail">
        <Form.Label><b>Registration/Identification No</b> 
        <br/>Input only if you purchase as a company. DO NOT USE country's prefix code for VAT numbers, e.g. GR.</Form.Label>
        <Form.Control type="text" required value={user.VATNumber} onChange={(e)=>{checkVatNum(e.target.value)}}/>
        </Form.Group>
        <br/> 
        {vatIsValid.valid &&
        
        <Alert variant="success">{vatIsValid.name}</Alert>
        
        }   

        {!vatIsValid.valid &&
        <Alert variant="warning">Please enter a valid Registration/Identification No</Alert>
        }
    
        </>  
      
      }

<br/>

  <Row><Col></Col>
  <Col md="auto">
      {user.ProfileUpdated==1 &&
      <>
      <Button  variant="secondary" onClick={()=>setProfileUpdated(1)} style={{width:"150px"}}>
      Cancel
    </Button>  
    &nbsp;     
    </>
      
      }

      <Button  disabled={!vatIsValid.valid} variant="dark" type="submit" style={{width:"150px"}}>
          Save
      </Button>                      
  </Col>
  </Row>           


      </Form>  
      </Container>

  }

          <Modal show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
            <Modal.Title>

            </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
              </Modal.Body>
              </Modal>

  <br/><br/><br/>
  </>
  )
}

import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Accordion} from 'react-bootstrap'
import axios from 'axios'
import {Redirect,useHistory,Link,useParams} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'
import Profile from './Profile'
import PricingPaymentMethod from './PricingPaymentMethod'
import Checkout from './Checkout'
import {PurchaseContext} from './PurchaseContext'


export default function Licence(props) {
    const [purchaseItem,setPurchaseItem]=useState({})
    const [isLoading,setIsLoading]=useState(false)
    const [endpoints,setEndpoints]=useState([])
    const [endpoint,setEndpoint]=useState({})
    const {lid}=useParams()
    const history=useHistory()


    useEffect(()=>{
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'eshopusrlicencesactivated/'+sessionStorage.getItem("userid")+'/'+lid,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
            }            
        })
      .then(res=>{
        if (res.data.length==1){
          setPurchaseItem(res.data[0])
          setEndpoints(res.data[0].EndPoint.split(','))
          setIsLoading(false)
          //createCheckoutSession(res.data[0].eshopuserslicencesid)
        }else{
          alert("An Error Has Occured")
          history.push('/licences')
        }
      })   

    },[])

    const endpointDets=(endpoint)=>{
      axios.get(process.env.REACT_APP_API_SERVER+'eshoppusrendpointdetails/'+endpoint,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
            }            
        })
      .then(res=>{
        setEndpoint(res.data[0])
      }) 
    }

    const endpointsList=endpoints.map((item,index)=>{
      
      return(
        <Accordion.Item eventKey={index}>
        <Accordion.Header onClick={()=>endpointDets(item)}><b>{purchaseItem.ApiBaseUrl}{item}</b></Accordion.Header>
        <Accordion.Body>
          <Table striped>
            <tbody>
              <tr>
                <td width="20%"><b>Endpoint:</b></td>
                <td>{purchaseItem.ApiBaseUrl}{item}</td>
              </tr>
              <tr>
                <td><b>Title:</b></td>
                <td>{endpoint.Title}</td>
              </tr>
              <tr>
                <td><b>Description:</b></td>
                <td>{endpoint.Description}</td>
              </tr>
              <tr>
                <td><b>Method:</b></td>
                <td>{endpoint.Method}</td>
              </tr>

              <tr>
                <td><b>{endpoint.Method} Params:</b></td>
                <td>{endpoint.Params}</td>
              </tr>
              <tr>
                <td><b>Charging Objects:</b></td>
                <td>{endpoint.ChargeObjects}, {endpoint.ChargeTypeValue} credits per line</td>
              </tr>
            </tbody>
          </Table>

        </Accordion.Body>
        </Accordion.Item>
       
      )
    })

  return (
       <>
       <Container fluid>
       {!isLoading && 
     <Alert variant="secondary" style={{backgroundColor:'#55505c',color:'#ffffff'}}>
       <h4>{purchaseItem.ElorusProductTitle}
     </h4></Alert>
      }
  
     {isLoading &&
      <>
   
       <Alert variant='warning'>...Loading, please wait.</Alert>

       </>
     }

     {!isLoading && 
     <>
     <Alert variant="secondary">Request Headers </Alert>

      <b>Authorization:</b> Bearer {purchaseItem.APIKEY}
      <br/><br/>

      <Alert variant="secondary">Endpoints</Alert>
      <Accordion>

          {endpointsList}

        </Accordion>
        <br/><br/>
        <Alert variant="secondary">Subscription Details</Alert> 
        <Table striped>
            <tbody>
              <tr>
                <td width="20%"><b>Subscription Type:</b></td>
                <td>{purchaseItem.SubscriptionType==1 ? 'Pay as You Go' : 'Time Subscription'}</td>
              </tr>
              <tr>
                <td><b>End Date:</b></td>
                <td>{purchaseItem.EndDate}</td>
              </tr>

              {purchaseItem.SubscriptionType==1 &&
              <tr>
              <td><b>Credits:</b></td>
              <td>{purchaseItem.CreditsLeft} €</td>
            </tr>


              }
              <tr>
              <td><b>Number of Daily Requests:</b></td>
              <td>{purchaseItem.DailyMaxRequests}</td>
            </tr>              
            </tbody>
          </Table> 
          <br/><br/>
          <Alert variant="secondary">Purchase Documents {purchaseItem.DocumentDate}</Alert> 
          <a href={purchaseItem.ElorusDocumentLink} target="_blank">{purchaseItem.ElorusDocumentLink} </a>
          <br/><br/><br/><br/>
     </>
     }
     </Container>
     </>
  )
}

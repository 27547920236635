import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Col,Row} from 'react-bootstrap'
import axios from 'axios'
import {Redirect,useHistory,Link} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'
import {PurchaseContext} from './PurchaseContext'
export default function Profile(props) {

    const [data,setData]=useState([])
    const [show,setShow]=useState(false)
    const [modalTitle,setModalTitle]=useState('')
    const [record,setRecord]=useState({})
    const [isLoading,setIsLoading]=useState(false)
    const [profileUpdated,setProfileUpdated]=useState(sessionStorage.getItem("profileUpdated")==1 ? true : false)
    const [user,setUser]=useState({})
    const [countries,setCountries]=useState([])
    const [vatIsValid,setVatIsValid]=useState({})
    const {activeTab,setActiveTab}=useContext(UserContext)
    const {purchaseTabs,setPurchaseTabs}=useContext(UserContext)
    

    const history=useHistory()

    useEffect(()=>{

        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'eshopusrprofile/'+sessionStorage.getItem("userid"),{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
              }            
          })
        .then(res1=>{
          setUser({...res1.data[0],lid:props.lid})
          if (res1.data[0].VATNumber.length>0){
            setVatIsValid({valid:true})
            // if (res1.data[0].CustomerType==1){
            //   checkVatNum(res1.data[0].VATNumber)
            // }
          }
          setIsLoading(false)
        }) 
        loadCounties()   
    
    
      },[])

      const loadCounties=()=>{
        axios.get(process.env.REACT_APP_API_SERVER+'eshopusrcountries',{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
                }            
            })
          .then(res=>{
            setCountries(res.data)
            //console.log(res.data)
          })        
      }



      const save=(e)=>{
        e.preventDefault()
    
        //console.log({...user})
        axios.put(process.env.REACT_APP_API_SERVER+'eshopusrprofile/'+sessionStorage.getItem("userid"),user,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
              }            
          })
          .then(res1=>{
    
          if (res1.data.err==0){
            sessionStorage.setItem('profileUpdated',1)
            sessionStorage.setItem('FirstName',user.FirstName)
            sessionStorage.setItem('LastName',user.LastName)
            setProfileUpdated(1)
            setVatIsValid({valid:true})
            setActiveTab('paymentmethod')
            purchaseTabs[0]=false
            setPurchaseTabs([...purchaseTabs])
            if (!props.lid){
              alert('Success!')
            }
          }else{
              alert('An error has occured. Please try again...')
          }
        })
      }

      const countriesList=countries.map(item=>{
        return(
            <option key={item.CountryID} value={item.CountryID}>{item.Country}</option>
        )
      })

      const changeCountry=(CountryID)=>{
        setVatIsValid({})
        const country=countries.filter(country=>country.CountryID==CountryID)[0]
        setUser({...user,VIESCode:country.VIESCode, VATNumber:'',CountryID,EU:country.EU,CountryName:country.CountryName})
      }
      
        const checkVatNum=(VatNumber)=>{
          
          setUser({...user,VATNumber:VatNumber})
          if (user.EU==1){
            const vatObj={
              CountryCode:user.VIESCode,
              VatNumber
            }
            //console.log(vatObj)
            axios.post(process.env.REACT_APP_API_SERVER+'eshopusrcheckvat',vatObj,{
              headers: {
                  'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
                  }            
              })
            .then(res=>{
              setVatIsValid(res.data)
      
              //console.log(res.data)
            }) 
          }else{
            if (VatNumber.length>5){
              setVatIsValid({...vatIsValid,valid:true,name:user.Company})
            }
          }
        }
      
      
        const changeCustomerType=(customerType)=>{
          if (customerType==1){
            setVatIsValid({})
          }else{
            setVatIsValid({valid:true})
          }
          setUser({...user,CustomerType:customerType,VATNumber:''})
        }
      
    return (

        <Container fluid>
            <Alert variant='secondary' style={{backgroundColor:'#55505c',color:'#ffffff'}}>
            
                <Row>
                <Col md="auto">
                <h4>User Details</h4>
                </Col>
                <Col></Col>

              </Row>


            </Alert>
            {isLoading &&
                <Alert variant='warning'>...Loading, please wait.</Alert>
            }
            {!isLoading &&
            <>
            
            <Form onSubmit={save}>

           


<Form.Group as={Row}  className="mb-3" controlId="formBasicPassword">   
    <Form.Label column sm={3}><b>Purchase as</b></Form.Label>    
    <Col><Form.Select required value={user.CustomerType} onChange={(e)=>changeCustomerType(e.target.value)}>
    <option value=''>[PURCHASE AS...]</option>
    <option value="1">Company</option>
    <option value="2">Individual</option>
    </Form.Select></Col>
</Form.Group>
<br/>      

{user.CustomerType==1 && user.CountryID &&
  <>
  <Form.Group as={Row} controlId="formBasicEmail">
  <Form.Label column sm={3}><b>Registration/Identification No</b> 
  </Form.Label>
  <Col><Form.Control type="text" required value={user.VATNumber} onChange={(e)=>{checkVatNum(e.target.value)}}/>
  <br/>Input only if you purchase as a company. DO NOT USE country's prefix code for VAT numbers, e.g. ES.
  
  </Col>
  
  </Form.Group>
  <br/> 
  {vatIsValid.valid &&
  
  <Alert variant="success">{vatIsValid.name}</Alert>
  
  }   

  {!vatIsValid.valid &&
  <Alert variant="warning">Please enter a valid Registration/Identification No</Alert>
  }

  </>  

}

<br/>
      <Form.Group as={Row} className="mb-3" controlId="formBasicPassword">   
          <Form.Label column sm={1}><b>Country</b></Form.Label>    
          <Col><Form.Select required value={user.CountryID} onChange={(e)=>changeCountry(e.target.value)}>
          <option value=''>[SELECT COUNTRY]</option>
          {countriesList}
          </Form.Select></Col>
      </Form.Group>
      <br/>
      <Form.Group as={Row} controlId="formBasicEmail">
          <Form.Label column sm={1}><b>Company</b></Form.Label>
          <Col>
          <Form.Control  type="text" required value={user.Company} onChange={(e)=>{setUser({...user,Company:e.target.value})}}/>
          </Col>
      </Form.Group>
      <br/>

      <Form.Group as={Row} controlId="formBasicEmail">
          <Form.Label column sm={1}><b>First Name</b></Form.Label>
          <Col>
          <Form.Control type="text" required value={user.FirstName} onChange={(e)=>{setUser({...user,FirstName:e.target.value})}}/>
          </Col>
      </Form.Group>
      <br/>

      <Form.Group as={Row} controlId="formBasicEmail">
          <Form.Label column sm={1}><b>Last Name</b></Form.Label>
          <Col>
          <Form.Control type="text" required value={user.LastName} onChange={(e)=>{setUser({...user,LastName:e.target.value})}}/>
          </Col>
      </Form.Group>
      <br/>

      <Form.Group as={Row} controlId="formBasicEmail">
          <Form.Label column sm={1}><b>Email</b></Form.Label>
          <Col>
          <Form.Control disabled={true} type="text" required value={user.Email} />
          </Col>
      </Form.Group>
      <br/>

      <Form.Group as={Row} controlId="formBasicEmail">
          <Form.Label column sm={1}><b>Telephone</b></Form.Label>
          <Col>
          <Form.Control type="text" required value={user.Telephone} onChange={(e)=>{setUser({...user,Telephone:e.target.value})}}/>
          </Col>
      </Form.Group>
      <br/>

      <Form.Group as={Row} controlId="formBasicEmail">
          <Form.Label column sm={1}><b>Address</b></Form.Label>
          <Col>
          <Form.Control type="text" required value={user.Address} onChange={(e)=>{setUser({...user,Address:e.target.value})}}/>
          </Col>
      </Form.Group>
      <br/>



      <Form.Group as={Row} controlId="formBasicEmail">
          <Form.Label column sm={1}><b>State/Province</b></Form.Label>
          <Col>
          <Form.Control type="text" required value={user.State} onChange={(e)=>{setUser({...user,State:e.target.value})}}/>
          </Col>
      </Form.Group>
      <br/>

      <Form.Group as={Row} controlId="formBasicEmail">
          <Form.Label column sm={1}><b>City</b></Form.Label>
          <Col>
          <Form.Control type="text" required value={user.City} onChange={(e)=>{setUser({...user,City:e.target.value})}}/>
          </Col>
      </Form.Group>
      <br/>

      <Form.Group as={Row} controlId="formBasicEmail">
          <Form.Label column sm={1}><b>Postal Code</b></Form.Label>
          <Col>
          <Form.Control type="text" required value={user.PostalCode} onChange={(e)=>{setUser({...user,PostalCode:e.target.value})}}/>
          </Col>
      </Form.Group>


      <br/>
      {props.lid &&
        <Navbar bg="light" fixed="bottom" >

          <table width="100%">
            <tbody>
            <tr>
              <td align="right">
              <Button  disabled={!vatIsValid.valid && user.CustomerType==1} variant="dark" type="submit" style={{width:"200px"}}>
                Save and Continue
              </Button> 
              </td>
              <td width="30"></td>
            </tr>
            </tbody>
          </table>

                     
   
        </Navbar>     
      }

      {!props.lid &&
        <>
          <p align="right">
          <Button  disabled={!vatIsValid.valid && user.CustomerType==1} variant="dark" type="submit" style={{width:"200px"}}>
              Save
            </Button> 
          </p>
        </>
      
      }

      </Form>             
            <br/><br/><hr/>
            
            </>
            }
        </Container>
    )
}

import React, {useState,useContext,createContext,useEffect,useRef}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Row,Col} from 'react-bootstrap'
import {useParams,useHistory} from 'react-router-dom'
import Logo from './nrrdlogo.png'
import bg from './Background.png'
import axios from 'axios'



export default function Precover() {
    const PWD=useRef()
    const PWD1=useRef()
    const {uid}=useParams()
    const history=useHistory()

    const pwdRecover=(e)=>{
        e.preventDefault()
        const p1=PWD.current.value
        const p2=PWD1.current.value

        if (p1===p2){
            if (checkPWD(p1)){
                const obj={
                    PWD:p1,
                    prcvid:uid
                }
                axios.post(process.env.REACT_APP_API_SERVER+'eshopuserspwdrecovery',obj).then(res=>{
                    //console.log(res.data)
                    if (res.data.err==0){
                        alert("New password set!")
                        history.push('/login')
                    }else{
                        alert('Error!!! Please try again.')
                    }
                })

            }else{
                alert('Password must contain letters a-z,A-Z and numbers 0-9')
            }
        }else{
            alert("Passwords don't match")
        }

    }

    const checkPWD=(pwd)=>{

        var az=0
        var nums=0
        var symbols=0
        var pwdl=0

        if (pwd.match(/[a-z]+/) || pwd.match(/[A-Z]+/)){
            az=1
        }else{
            az=0
        }

        if (pwd.match(/[0-9]+/)){
            nums=1
        }else{
            nums=0
        }

        // if (pwd.match(/[$@#&!%^*()]+/)){
        //     symbols=1
        // }else{
        //     symbols=0 
        // }      

        symbols=1

        if (pwd.length>5){
            pwdl=1
        }else{
            pwdl=0
        }

        if (az==1 && nums==1 && symbols==1 && pwdl==1){
            return(true)
        }else{
            return(false)
        }

    }


    return (
        <Container fluid style={{background:`url(${bg})`,width:'100vw',height:'100vh',backgroundSize:'cover'}}>
        <Container style={{width:'70vw'}}>
           


        


            <>
            <Container style={{width:'70%'}}>
            <Navbar>
            <Container>
                <Navbar.Brand>
                    <img
                        src={Logo}
                        style={{width:'100%'}}
                    />
                </Navbar.Brand>
            </Container>
            </Navbar> 
            </Container>    
            <br/> <br/>        
            <Container  style={{width:'70%',backgroundColor:'#f8f9fa',justifyContent:'center',justifyItems:'center',alignItems:'center',alignContent:'center',borderRadius:'12px',borderColor:'#686766',borderStyle:'solid',borderWidth:'2px'}}>

            <br/>            
            <Alert variant='warning'><b>Password Recovery</b>: min length 6 chars, letters a-z, A-Z and numbers 0-9</Alert>
            <Form onSubmit={pwdRecover}>
            <table width="100%">
                <tbody>
                <tr>
                    <td width="20%" align="right"><font face="tahoma" size="2" color="#686766"><b>New Password</b></font></td>
                    <td width="10"></td>
                    <td align="center"><Form.Control minLength={6} type="password" ref={PWD} required style={{width:'100%'}}/></td>
                </tr>
                <tr><td colSpan="3" height="20"></td></tr>
                <tr>
                    <td width="20%" align="right"><font face="tahoma" size="2" color="#686766"><b>Confirm New Password</b></font></td>
                    <td width="10"></td>
                    <td align="center"><Form.Control minLength={6}  type="password" ref={PWD1} required style={{width:'100%'}}/></td>
                </tr>

                <tr><td colSpan="3" height="20"></td></tr>
                <tr><td colSpan="3" align="center">
                    <Button style={{backgroundColor:'#80ca28',borderColor:'#80ca28',width:'40%'}} type="submit">
                       Recover Password
                    </Button>                    
                </td></tr>  
                </tbody>                              
            </table>
            <br/><br/>



            </Form>
            </Container>
            </>

    

        </Container>
        </Container>
    )
}

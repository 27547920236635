import React, {useState,useContext,createContext,useEffect,useRef}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Row,Col} from 'react-bootstrap'
import {Redirect,useHistory,Link} from 'react-router-dom'
import Logo from './protected/nrrdlogo.png'
import bg from './protected/Background.png'
import axios from 'axios'
import {UserContext} from '../context/UserContext'


export default function Login() {
    const PWD=useRef()
    const UN=useRef()
    const Email=useRef()
    const {setIsAuth}=useContext(UserContext)
    const {setToken}=useContext(UserContext)
    const {logout}=useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const [loginFailed,setLoginFailed]=useState(false)
    const [show,setShow]=useState(false)
    const history=useHistory()

    const handleClose=()=>{
        setShow(false)
    }


    const login=(e)=>{
        e.preventDefault()
        const xUN=UN.current.value
        const xPWD=PWD.current.value
        const obj={UN:xUN,PWD:xPWD}
        setIsLoading(true)
        axios.post(process.env.REACT_APP_API_SERVER+'logineshop',obj).then(res=>{
            //console.log(res.data)
            if (res.data.err==0){
                setIsAuth(true)
                sessionStorage.setItem("isAuthEshop", 1)
                sessionStorage.setItem("eshoptoken", res.data.token)
                sessionStorage.setItem("userid", res.data.user[0].id)
                sessionStorage.setItem("userFullName",res.data.user[0].FirstName+' '+res.data.user[0].LastName)
                sessionStorage.setItem("profileUpdated",res.data.user[0].ProfileUpdated)
                // sessionStorage.setItem("AuthString",res.data.AuthString)
                // sessionStorage.setItem("ApiBaseUrl",res.data.ApiBaseUrl)
                //console.log(res.data)
                setIsLoading(false)
                history.push('/licences')                
            }else{
                logout()
                setIsLoading(false)
                setLoginFailed(true)               
            }
        })
    }

    const checkEmail=(e)=>{
        e.preventDefault()
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'eshopuserspwdrecovery/'+Email.current.value)
        .then(res=>{
            if (res.data.err==0){
                alert('An email message with the recovery link has been sent to '+Email.current.value)
                setShow(false)
                setIsLoading(false)
            }else{
                setIsLoading(false)
                alert("Email "+Email.current.value+" does not exist.")
            }

        })         
    }


    return (
        <Container fluid style={{background:`url(${bg})`,width:'100vw',height:'100vh',backgroundSize:'cover'}}>
        <Container style={{width:'70vw'}}>
           


            {loginFailed &&
            <Alert variant="danger" dismissible onClose={()=>{setLoginFailed(false)}}>Login Failed</Alert>
            
            }

            {!isLoading && 
            <>
            <Container style={{width:'70%'}}>
            <Navbar>
            <Container>
                <Navbar.Brand>
                    <img
                        src={Logo}
                        style={{width:'100%'}}
                    />
                </Navbar.Brand>
            </Container>
            </Navbar> 
            </Container>    
            <br/> <br/>        
            <Container  style={{width:'70%',backgroundColor:'#f8f9fa',justifyContent:'center',justifyItems:'center',alignItems:'center',alignContent:'center',borderRadius:'12px',borderColor:'#686766',borderStyle:'solid',borderWidth:'2px'}}>

            <br/>            
            
            <Form onSubmit={login}>
            {/* <Form.Group className="mb-3" style={{width:'30vw'}}>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={UN} required/>

            </Form.Group>

            <Form.Group className="mb-3" style={{width:'30vw'}}>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={PWD} required/>
            </Form.Group> */}
            <br/><br/>
            <table width="100%">
                <tr>
                    <td width="10%" align="right"><font face="tahoma" size="2" color="#686766"><b>Email</b></font></td>
                    <td width="10"></td>
                    <td align="center"><Form.Control type="email" ref={UN} required style={{width:'100%'}}/></td>
                </tr>
                <tr><td colspan="3" height="20"></td></tr>
                <tr>
                    <td width="10%" align="right"><font face="tahoma" size="2" color="#686766"><b>Password</b></font></td>
                    <td width="10"></td>
                    <td align="center"><Form.Control type="password" ref={PWD} required style={{width:'100%'}}/></td>
                </tr>
                <tr><td colspan="3" height="40"></td></tr>  

                <tr><td colspan="3" align="center">
                    <Button style={{backgroundColor:'#80ca28',borderColor:'#80ca28',width:'40%'}} type="submit">
                        Login
                    </Button>                    
                </td></tr>
                <tr><td colspan="3" height="40"></td></tr>
                <tr><td colspan="3" align="center">
                <Button variant="link" style={{textDecoration:'none'}} onClick={()=>setShow(true)}>
                <font face="tahoma" size="4" color="#80CA28">Forgot Password?</font>
                </Button>
                </td></tr>
                <tr><td colspan="3" height="40"></td></tr>
                <tr><td colspan="3" align="center">
                    <a href="https://www.neurored.com/" style={{textDecoration:'none'}} target="_blank">
                    <font face="tahoma" size="4" color="#084888">Home</font>
                    </a>
                    &nbsp;&nbsp;
                    <font face="tahoma" size="4" color="#084888">•</font>
                    &nbsp;&nbsp;

                    <a href="https://www.neurored.com/pricing-and-support/" style={{textDecoration:'none'}} target="_blank">
                    <font face="tahoma" size="4" color="#084888">Pricing</font>
                    </a>
                    &nbsp;&nbsp;
                    <font face="tahoma" size="4" color="#084888">•</font>
                    &nbsp;&nbsp;

                    <a href="mailto:support@neurored.com" style={{textDecoration:'none'}} target="_blank">
                    <font face="tahoma" size="4" color="#084888">Support</font>
                    </a>
                    &nbsp;&nbsp;
                    <font face="tahoma" size="4" color="#084888">•</font>
                    &nbsp;&nbsp;

                    <a href="https://www.neurored.com/blog/" style={{textDecoration:'none'}} target="_blank">
                    <font face="tahoma" size="4" color="#084888">Blog</font>
                    </a>
                    &nbsp;&nbsp;
                    <font face="tahoma" size="4" color="#084888">•</font>
                    &nbsp;&nbsp;

                    <a href="mailto:info@neurored.com" style={{textDecoration:'none'}} target="_blank">
                    <font face="tahoma" size="4" color="#084888">Contact Us</font>
                    </a>

                </td></tr>

                <tr><td colspan="3" height="40"></td></tr>                  
            </table>


            </Form>
            </Container>
            </>
            }

            {isLoading && 
            <Alert variant='info'>Loading, please wait...</Alert>
            
            
            }

            <Modal  show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                <Modal.Title>
                    Please Enter Your Login Email Address
                </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                <Form onSubmit={checkEmail}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" required ref={Email}/>
                </Form.Group>
                <br/><br/>
                <p align="right">
                    <Button disabled={isLoading} variant="dark" type="submit">Get Password Recovery Link</Button>
                </p>
                </Form>
                </Modal.Body>
            </Modal>           

        </Container>
        </Container>
    )
}

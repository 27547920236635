import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Col,Row,Tabs,Tab} from 'react-bootstrap'
import axios from 'axios'
import {Redirect,useHistory,Link,useParams} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'
import Profile from './Profile'
import PricingPaymentMethod from './PricingPaymentMethod'
import Checkout from './Checkout'
import {PurchaseContext} from './PurchaseContext'


export default function Purchase(props) {
    const [purchaseItem,setPurchaseItem]=useState({})
    const [isLoading,setIsLoading]=useState(false)
    const [url,setUrl]=useState('')
    const {lid}=useParams()
    const history=useHistory()
    const {activeTab,setActiveTab}=useContext(UserContext)
    const {purchaseTabs,setPurchaseTabs}=useContext(UserContext)
    

    useEffect(()=>{
      setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'eshopusrlicences/'+sessionStorage.getItem("userid")+'/'+lid,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
            }            
        })
      .then(res=>{
        if (res.data.length==1){
          setPurchaseItem(res.data[0])
          setIsLoading(false)
          //createCheckoutSession(res.data[0].eshopuserslicencesid)
        }else{
          alert("An Error Has Occured")
          history.push('/licences')
        }
      })   

    },[])


    const createCheckoutSession=(id)=>{
        
        
        const checkoutObj={
            id
        }
        axios.post(process.env.REACT_APP_API_SERVER+'checkout',checkoutObj,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
                }            
            })
          .then(res=>{
            setUrl(res.data.url)
            setIsLoading(false)
    
            
          }).catch(err=>{
              console.log(err)
              alert('An Error Has Occured')
          })         
    }

  return (
       <>
       <Container fluid>
       {!isLoading && 
     <Alert variant="secondary" style={{backgroundColor:'#55505c',color:'#ffffff'}}>
       <h4>{purchaseItem.ElorusProductTitle}
     </h4></Alert>
      }
  
     {isLoading &&
      <>
      <Alert variant="secondary" style={{backgroundColor:'#55505c',color:'#ffffff'}}>
        <h4>Purchase Product
      </h4></Alert>     
       <Alert variant='warning'>...Loading, please wait.</Alert>

       </>
     }

     {!isLoading && 
     <>
     <PurchaseContext.Provider value={{purchaseItem,setPurchaseItem}}>
     <Tabs activeKey={activeTab}  className='mb-4' variant="pills">
       <Tab  eventKey="profile" title="User Details" disabled>
           <Profile lid={lid}/>
       </Tab>

       <Tab  eventKey="paymentmethod" title="Pricing and Payment Method" disabled>
       {/* <a href={url}>Buy With Stripe</a> */}
       <PricingPaymentMethod lid={lid}/>
       </Tab>

       <Tab  eventKey="checkout" title="Checkout" disabled>
           <Checkout lid={lid}/>
       </Tab>

     </Tabs>
     </PurchaseContext.Provider>
     
     </>
     }
     </Container>
     </>
  )
}

import React,{useContext} from 'react'
import {Route,Redirect} from 'react-router-dom'
import {UserContext} from './context/UserContext'
//import Header from './components/protected/Header'
import {Container,Row,Col,Navbar} from 'react-bootstrap'
//import Footer from './components/protected/Footer'
import ApplicationDrawer from './components/protected/ApplicationDrawer'
import SideBar from './components/protected/SideBar'

export default function ProtectedRoute({component:Component,...rest}) {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {setToken}=useContext(UserContext)




    return (
        <Route {...rest} render={props=>{
        
            if (isAuth || (sessionStorage.getItem("isAuthEshop")==1)){

                setIsAuth(true)

                return (


                    // <Container fluid>
                    // <Row className="flex-xl-nowrap" >
                    //     {/* <Col style={ {border:'1px solid #f0f0f0',alignItems:'top'}} as={ ApplicationDrawer } xs={ 12 } md={ 3 } lg={ 2 }><ApplicationDrawer/></Col> */}
                    //     <Col style={ {alignItems:'top'}} xs='auto' md='auto' lg='auto'><SideBar/></Col>
                    //     <Col ><Component /></Col>
                    // </Row>
                    // </Container>


                    <Container fluid style={{height:'100vh'}}>


                    <Row className="h-100">
                        <Col md="auto" sm="auto"
                            style={{
                                backgroundColor:'#44404a'
                            }}
                        >
                            <SideBar/>
                        </Col>
                        <Col>
                            <div style={{width:'100%',height:'95vh'}}  className="overflow-auto">
                            <Component />
                           
                            {/* <Footer/> */}
                            </div>
                        </Col>
                    </Row>


                    </Container>


                )
            }else{

                 return <Redirect to={{pathname:"/login",state:{from: props.location}}}/>

                
            }
        }}/>
    )
}

import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Form,Button,Container,Card,Alert,Table,Modal,Navbar,NavDropdown,Nav,Col,Row} from 'react-bootstrap'
import axios from 'axios'
import {Redirect,useHistory,Link} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'
import {PurchaseContext} from './PurchaseContext'
import Stripe from './stripe.png'
import Stripe1 from './stripe1.png'

export default function Checkout(props) {

    const {activeTab,setActiveTab}=useContext(UserContext)
    const [url,setUrl]=useState('')
    const [isLoading,setIsLoading]=useState(false)
    const {purchaseItem,setPurchaseItem}=useContext(PurchaseContext)
    const history=useHistory()
    const [purObj,setPurObj]=useState({})

    useEffect(()=>{
        if (activeTab=='checkout' && purchaseItem.PaymentMethod==1 ){
          createCheckoutSession()
        }
        
    },[activeTab])

    const createCheckoutSession=()=>{
        
        //console.log(purchaseItem)
        setIsLoading(true)
        const checkoutObj={
            ugsterw:props.lid
        }
        axios.post(process.env.REACT_APP_API_SERVER+'eshopusrcheckout',checkoutObj,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
                }            
            })
          .then(res=>{
            setUrl(res.data.url)
            setIsLoading(false)
    
            
          }).catch(err=>{
              //console.log(err)
              alert('An Error Has Occured')
          })         
    }

    const sendOrder=()=>{
      setIsLoading(true)
      const checkoutObj={
          ugsterw:props.lid
      }
      axios.post(process.env.REACT_APP_API_SERVER+'eshopusrfinishbt',checkoutObj,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("eshoptoken")
              }            
          })
        .then(res=>{
          if (res.data.err==0){
            history.push('/licences')
          }else{
            alert('An Error Has Occured')
          }
          
          
        }).catch(err=>{
            //console.log(err)
            alert('An Error Has Occured')
        })       
    }

  return (
    <Container fluid>
    <Alert variant='secondary' style={{backgroundColor:'#55505c',color:'#ffffff'}}>
    
        <Row>
        <Col md="auto">
        <h4>Checkout</h4>
        </Col>
        <Col></Col>

      </Row>

    </Alert>
    {isLoading &&
        <Alert variant='warning'>...Loading, please wait.</Alert>
    }
    {!isLoading &&
    <>
    {purchaseItem.PaymentMethod==1 &&
    <>
    <p align="center">
    
    <img src={Stripe1} width="250"/>
    <br/><br/>
    <Button style={{textDecoration:'none',width:'250px',height:'40px'}} variant='success' type='link' href={url}>Pay With Stripe</Button>

    
    {/* <a href={url} style={{textDecoration:'none'}}><img src={Stripe} border="0"/></a> */}
    </p>
    </>
    }

    {purchaseItem.PaymentMethod==2 &&
    <>
      <b>Bank Account Details (To Receive payments in Euros):</b>
      <br/><br/>
      Bank Name: BBVA Avda de Europa, 2 28224 Pozuelo de Alarcón Madrid
      <br/><br/>
      IBAN: ES5401821959000011504692
      <br/><br/>
      Account Number: 01821959000011504692
      <br/><br/>
      SWIFT: BBVAESMMXXX
      <br/><br/>
      Beneficiary: Neurored. S.A.
      <br/><br/>
      <b>TOTAL : </b>{purchaseItem.payValue}€
      <br/><br/>
      <Button variant="dark" onClick={()=>sendOrder()}>Send Your Order</Button>
      </>
    }
    
    </>
    }

    <Navbar bg="light" fixed="bottom" >
      <table width="100%">
        <tbody>
        <tr>
          <td align="right">
          <Button variant="secondary" onClick={()=>setActiveTab('paymentmethod')} style={{width:"200px"}}>Back</Button>  

          </td>
          <td width="30"></td>
        </tr>
        </tbody>
      </table>
  </Navbar>


    </Container>
  )
}
